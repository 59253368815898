/* eslint radix: ["error", "as-needed"] */

import { isValid as cnpjValidator } from '@fnando/cnpj';
import { isValid as cpfValidator } from '@fnando/cpf';
import Jsrsasign from 'jsrsasign';
import lamejs from 'lamejs';
import mime from 'mime-types';
import moment from 'moment';
import { useEffect, useRef } from 'react';
import { defineMessages } from 'react-intl';
import MPEGMode from 'lamejs/src/js/MPEGMode';
import Lame from 'lamejs/src/js/Lame';
import BitStream from 'lamejs/src/js/BitStream';

import { AUTH0_PASSWORD, QUEUE_INTERACTION_STATES } from './consts';

window.MPEGMode = MPEGMode;
window.Lame = Lame;
window.BitStream = BitStream;

export const updateObject = (oldObject, updatedProperties) => (
	{ ...oldObject, ...updatedProperties }
);

export const decodeJWT = (dataHash) => {
	const data = dataHash.split('.');
	const uClaim = decodeURIComponent(escape(Jsrsasign.b64utos(data[1])));
	return Jsrsasign.KJUR.jws.JWS.readSafeJSONString(uClaim);
};

export const timeInfoFormat1 = (time) => {
	const midnight = moment().startOf('day');
	const createdAt = moment(time);
	const diff = midnight.diff(createdAt);

	if (diff <= 0) return createdAt.format('HH:mm');
	return createdAt.format('DD/MM/YY');
};

export const timeInfoFormat2 = time => moment(time).format('HH:mm');

export const timeInfoFormat3 = time => moment(time).format('LL');

export const timeInfoFormat4 = time => moment(time).format('DD/MM/YYYY');

export const timeInfoFormat5 = (time) => {
	const midnight = moment().startOf('day');
	const now = moment(new Date());
	const createdAt = moment(time);
	const diff = midnight.diff(createdAt);

	if ((diff > 0)) return createdAt.format('DD/MM/YY');

	const duration = moment.duration(now.diff(createdAt));
	const minutes = Math.floor(duration.asMinutes());

	if (minutes > 59) return createdAt.format('HH:mm');

	if (minutes) return `${minutes}m`;

	return 'Agora';
};

export const formatTimeToDateAndHours = time => moment(time).format('DD/MM/YYYY HH:mm');

// FIXME: BACKEND_MESSAGE_PATTERN
export const mapNewMessageItem = (newMessage) => {
	const {
		agentName,
		agentPhoto,
		messageId,
		id,
		content = '',
		contentType,
		type,
		createdAt,
		time,
		url,
		origin,
		direction,
		state,
		status,
		message,
		source,
		attachments,
		tempId,
		media,
		fileName,
		expired,
		referenceId,
		externalId
	} = newMessage;
	const msg = {
		agentName,
		agentPhoto,
		messageId: messageId || id || tempId,
		contentType: contentType || type || media,
		createdAt: createdAt || time,
		content: content || message,
		url,
		origin: origin || direction || source,
		status: status || state,
		expired,
		attachments,
		fileName,
		referenceId,
		externalId
	};

	return msg;
};

export const messageAlreadyExists = (messages, { id, messageId } = {}) => messages.some(msg => (msg.messageId === id || msg.messageId === messageId) && msg.type !== 'state');

export const mapInternalMessageIfNecessary = (messages, newMessage) => {
	if (messageAlreadyExists(messages, newMessage)) return messages;

	return [...messages, mapNewMessageItem({
		...newMessage,
		status: 'read',
		contentType: 'text',
		agentName: newMessage.agent ? newMessage.agent.name : ''
	})];
};

export const mapNewCustomerItem = (customer) => {
	const parsedCustomer = { ...customer };
	const { history } = parsedCustomer;
	if (!history) {
		parsedCustomer.history = [];
	}
	return parsedCustomer;
};

// FIXME: BACKEND_INTERACTION_PATTERN
export const mapNewInteractionItem = (newInteraction) => {
	const {
		currentState,
		customer,
		customerInfo,
		customerKey,
		department,
		hash,
		interactionHash,
		interactionType,
		media,
		message = {},
		messages = [],
		state,
		type
	} = newInteraction;
	const parsedCustomer = customer || customerInfo;
	const hasMessage = message && message.constructor === Object && Object.keys(message).length > 0;
	const hasMessages = messages.length > 0;
	const parsedMessages = !hasMessages && hasMessage ? [{ ...message, status: 'read', contentType: 'text' }] : messages;
	const parsedInteraction = {
		...newInteraction,
		currentState: state || currentState,
		customerInfo: {
			...mapNewCustomerItem(parsedCustomer),
			customerKey: parsedCustomer ? parsedCustomer.customerKey : customerKey
		},
		department: department && department.constructor === String ? { id: null, name: department } : department,
		interactionHash: interactionHash || hash.toString(),
		interactionType: media || interactionType || type,
		messages: parsedMessages.map(messageItem => mapNewMessageItem(messageItem))
	};

	return parsedInteraction;
};

export const checkValidity = (value, rules, label) => {
	let isValid = true;
	let validationMessage = {};

	const formattedLabel = label.replace('*', '');
	if (!rules) {
		return {
			isValid,
			validationMessage
		};
	}

	const messages = defineMessages({
		isRequired: {
			id: 'checkValidity.isRequired',
			defaultMessage: 'Obrigatório'
		},
		isInvalid: {
			id: 'checkValidity.isInvalid',
			defaultMessage: 'Inválido'
		},
		isPasswordInvalid: {
			id: 'checkValidity.isPasswordInvalid',
			defaultMessage: 'Mínimo 8 caracteres, sendo uma letra minúscula, uma letra maiúscula, um número e um caracter especial'
		}
	});

	if (rules.isRequired) {
		isValid = value.trim() !== '' && isValid;
		if (!isValid) validationMessage = { label: formattedLabel, intlObject: messages.isRequired };
	}

	if (rules.isEmail && value !== '') {
		const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
		isValid = pattern.test(value) && isValid;
		if (!isValid) validationMessage = { label: formattedLabel, intlObject: messages.isInvalid };
	}

	if (rules.isCpf && value !== '') {
		isValid = cpfValidator(value);
		if (!isValid) validationMessage = { label: formattedLabel, intlObject: messages.isInvalid };
	}

	if (rules.isCnpj && value !== '') {
		isValid = cpfValidator(value);
		if (!isValid) validationMessage = { label: formattedLabel, intlObject: messages.isInvalid };
	}

	if (rules.isCpfCnpj && value !== '') {
		const isCpfValid = cpfValidator(value);
		const isCnpjValid = cnpjValidator(value);

		isValid = isCnpjValid || isCpfValid;
		if (!isValid) validationMessage = { label: formattedLabel, intlObject: messages.isInvalid };
	}

	if (rules.isNumeric && value !== '') {
		const pattern = /^\+?\d+$/;
		isValid = pattern.test(value) && isValid;
		if (!isValid) validationMessage = { label: formattedLabel, intlObject: messages.isInvalid };
	}

	if (rules.isPassword && value !== '') {
		const pattern = AUTH0_PASSWORD;
		isValid = pattern.test(value) && isValid;
		if (!isValid) validationMessage = { label: formattedLabel, intlObject: messages.isPasswordInvalid };
	}

	return {
		isValid,
		validationMessage
	};
};

export const getCustomerFieldValue = (fieldName, fields) => {
	if (fields && fields.constructor === Array) {
		const fieldItem = fields.find(field => field.name === fieldName);
		if (fieldItem && fieldItem.value) return fieldItem.value;
	}

	return fields[fieldName];
};

export const parseCustomerName = (customerName) => {
	if (customerName && customerName.constructor === String) {
		const names = customerName.split(' ');
		if (names.length === 1) return `${names[0]}`;
		return `${names[0]} ${names[names.length - 1]}`;
	}

	return null;
};

export const getMainIdentifierFromInteraction = (interaction) => {
	const parsedInteraction = mapNewInteractionItem(interaction);
	const { customerInfo } = parsedInteraction;

	if (customerInfo && customerInfo.fields) {
		return parseCustomerName(getCustomerFieldValue('main_identifier', customerInfo.fields));
	}

	return 'Não identificado';
};

export const getLastMessage = (messages = []) => messages.reverse().find(({
	contentType,
	media,
	mediaType,
	type
}) => (!['date', 'state'].includes(contentType || media || type || mediaType))) || {};

export const setRequestStatus = (status, hash, error, count, ended) => {
	switch (status) {
	case 'failed':
		return {
			loading: false,
			error,
			hash,
			changed: false,
			success: false
		};
	case 'success':
		return {
			loading: false,
			error: null,
			hash,
			changed: false,
			success: true,
			count,
			ended
		};
	case 'clear':
		return {
			loading: false,
			error: null,
			hash,
			changed: false,
			success: false
		};
	case 'changed':
		return {
			loading: false,
			error: null,
			hash,
			changed: true,
			success: false
		};
	default:
		return {
			loading: true,
			error: null,
			hash,
			changed: false,
			success: false
		};
	}
};

export const manageInterectionStatus = (state, type, name, error, interactionHash = null) => {
	const currentInteractionHash = interactionHash || state.currentInteractionHash;
	const request = state[name];
	const hasRequest = request.find(requestItem => requestItem.hash === currentInteractionHash);

	if (hasRequest) {
		const updatedRequest = state[name].map((requestItem) => {
			if (requestItem.hash === currentInteractionHash) {
				return setRequestStatus(type, currentInteractionHash, error);
			}
			return requestItem;
		});

		return updatedRequest;
	}

	const updatedRequest = [...state[name]];
	updatedRequest.push(setRequestStatus(null || type, currentInteractionHash));

	return updatedRequest;
};

export const mapFormFieldItem = (field, customerInfoValue) => {
	const {
		id, name, isRequired, label, isUnique, type, isMultiple
	} = field;

	return {
		id,
		name,
		label,
		value: customerInfoValue || [],
		validation: {
			isRequired,
			isUnique,
			isEmail: type === 'email',
			isNumeric: type === 'phone',
			isCpfCnpj: type.toLowerCase() === 'cpf/cnpj',
			isCpf: type.toLowerCase() === 'cpf',
			isCnpj: type.toLowerCase() === 'cnpj',
			isPassword: type.toLowerCase() === 'password'
		},
		validationMessage: '',
		isValid: false,
		wasTouched: false,
		isMultiple,
		createdField: false
	};
};

export const fillFormFieldsWithCustomer = (formFields, customerInfo) => {
	const updatedFields = [];
	if (formFields) {
		formFields.forEach((field) => {
			const {
				id, name, isRequired, label, showable, isUnique, type, belongsToCustomer, isMultiple
			} = field;

			let customerInfoValue = '';
			if (Object.keys(customerInfo).length > 0 && customerInfo.fields) {
				customerInfoValue = getCustomerFieldValue(name, customerInfo.fields);
			}

			if (showable && belongsToCustomer) {
				if (customerInfoValue && customerInfoValue.constructor === Array) {
					if (customerInfoValue.length === 0) {
						updatedFields.push(mapFormFieldItem(field, customerInfoValue));
					} else {
						customerInfoValue.forEach((value, index) => {
							updatedFields.push({
								id: index === 0 ? id : `${new Date().getTime()}${type}${index}`,
								name,
								label,
								value,
								validation: {
									isRequired,
									isUnique,
									isEmail: type === 'email',
									isNumeric: type === 'phone',
									isCpfCnpj: type.toLowerCase() === 'cpf/cnpj',
									isCpf: type.toLowerCase() === 'cpf',
									isCnpj: type.toLowerCase() === 'cnpj'
								},
								validationMessage: '',
								isValid: false,
								wasTouched: false,
								isMultiple,
								createdField: index !== 0
							});
						});
					}
				} else {
					updatedFields.push(mapFormFieldItem(field, customerInfoValue));
				}
			}
		});
	}

	return updatedFields;
};


export const pushHashToArray = (state, action, name) => {
	const updatedArray = [...state[name]];
	updatedArray.push(action.payload);
	return updatedArray;
};

export const removeHashFromArray = (state, action, name) => {
	const updatedArray = state[name].filter(
		hash => action.payload !== hash
	);
	return updatedArray;
};

export const removeHashFromObjectArray = (state, action, name) => {
	const updatedArray = state[name].filter(
		item => action.payload !== item.hash
	);
	return updatedArray;
};

export const initNotification = async () => {
	if ('Notification' in window && Notification.permission !== 'denied') await Notification.requestPermission();
};

export const newNotification = (title, body) => {
	if ('Notification' in window && Notification && Notification.permission === 'granted') {
		try {
			const notification = new Notification(title, { body });

			notification.onclick = (e) => {
				e.preventDefault();
				window.focus();
				notification.close();
			};
		} catch (error) {
			return false;
		}
	}

	return false;
};

export const avatarColors = (char) => {
	const colors = ['#00ade5', '#6d73cf', '#ffbd00', '#ff5719'];
	return colors[(char.toLowerCase().charCodeAt()) % colors.length];
};

export const interactionTypeLabel = (type) => {
	switch (type.toLowerCase()) {
	case 'telegram':
		return 'Telegram';
	case 'whatsapp':
		return 'WhatsApp';
	case 'facebook':
		return 'Facebook Messenger';
	case 'sms':
		return 'sms';
	case 'meli_qst':
	case 'meli_msg':
		return 'Mercado Livre';
	case 'email':
		return 'E-mail';
	case 'instagram':
		return 'Instagram';
	case 'teams':
		return 'Teams';
	case 'phone':
		return 'Voz';
	case 'phone_conf':
	case 'conference':
	case 'addToConf':
		return 'Conferência';
	default:
		return 'Webchat';
	}
};


export const interactionTypeImage = (type, isPartner) => {
	switch (type.toLowerCase()) {
	case 'telegram':
		return 'telegram';
	case 'whatsapp':
		return 'whatsapp';
	case 'facebook':
		return 'messenger';
	case 'sms':
		return 'sms';
	case 'meli_qst':
	case 'meli_msg':
		return 'mercadolivre';
	case 'email':
		return 'email';
	case 'instagram':
		return 'instagram';
	case 'teams':
		return 'teams';
	case 'phone':
	case 'phone_conf':
	case 'conference':
	case 'addToConf':
		return 'phoneInteractionIcon';
	default:
		return isPartner ? 'chat' : 'omzchat';
	}
};

export const interactionTypeColor = (type = '', isPartner) => {
	switch (type.toLowerCase()) {
	case 'telegram':
		return '#61a8de';
	case 'whatsapp':
		return '#25d366';
	case 'facebook':
		return '#0084ff';
	case 'sms':
		return '#64c12c';
	case 'meli_qst':
	case 'meli_msg':
		return '#324480';
	case 'email':
		return '#d66a13';
	case 'offcontact':
		return '#5f3f8a';
	case 'instagram':
		return '#c13584';
	default:
		return isPartner ? '#ec383b' : '#ec383b';
	}
};

export const isEmpty = obj => Object.keys(obj).length === 0 && obj.constructor === Object;

export const getTaskCustomerName = (customer) => {
	if (customer && customer.fields) return getCustomerFieldValue('main_identifier', customer.fields);
	return null;
};

export const parsePhoneNumber = (value) => {
	if (value && value.constructor === Array && value.length > 0) return value[0].replace(/([^0-9*#+])/g, '');
	if (value && value.constructor === String) return value.replace(/([^0-9*#+])/g, '');
	return value;
};

export const formatFormFields = (formFields) => {
	const updatedFields = [];
	if (formFields) {
		formFields.forEach((field) => {
			const {
				id, name, isRequired, label, showable, isUnique, type, isMultiple, belongsToCustomer
			} = field;

			if (showable && belongsToCustomer) {
				updatedFields.push({
					id,
					name,
					label,
					value: '',
					validation: {
						isRequired,
						isUnique,
						isEmail: type === 'email',
						isNumeric: type === 'phone',
						isCpfCnpj: type.toLowerCase() === 'cpf/cnpj',
						isCpf: type.toLowerCase() === 'cpf',
						isCnpj: type.toLowerCase() === 'cnpj'
					},
					isMultiple,
					validationMessage: '',
					isValid: false,
					wasTouched: false
				});
			}
		});
	}
	return updatedFields;
};

export const fillTasks = (fillInfo, formControls) => {
	const editMapping = {
		taskName: 'name',
		taskDescription: 'description',
		taskCustomer: 'customer',
		taskDate: '',
		taskTime: ''
	};

	const editMappingExtras = {
		stNumber: 'stNumber',
		taskStatus: 'taskStatus',
		taskContactMade: 'taskContactMade',
		taskSupportInfo: 'taskSupportInfo',
		taskScheduleDate: 'taskScheduleDate',
		taskContactDate: 'taskContactDate',
		taskContactReason: 'taskContactReason',
		taskContractQuestions: 'taskContractQuestions',
		taskSupportNegotiation: 'taskSupportNegotiation',
		taskComercialNegotiation: 'taskComercialNegotiation',
		taskTechnicalNegotiation: 'taskTechnicalNegotiation',
		taskCustomerQuoteRevision: 'taskCustomerQuoteRevision',
		taskTechnicalQuoteRevision: 'taskTechnicalQuoteRevision',
		taskFutureContractSpecification: 'taskFutureContractSpecification',
		taskCustomerRequestsCancellation: 'taskCustomerRequestsCancellation'
	};

	let nextSelect = null;
	const filledCustomer = formControls.map((formItem) => {
		if (Object.prototype.hasOwnProperty.call(editMapping, formItem.name)) {
			const expireTime = fillInfo.expiresAt;
			if (formItem.name === 'taskCustomer') {
				let value = '';
				if (fillInfo && fillInfo.customer && fillInfo.customer.fields) {
					const name = getCustomerFieldValue('main_identifier', fillInfo.customer.fields);
					value = name;
				}
				return {
					...formItem,
					value
				};
			}

			if (formItem.name === 'taskDate') {
				return {
					...formItem,
					value: expireTime ? moment.utc(fillInfo.expiresAt).format('DD-MM-YYYY') : ''
				};
			}

			if (formItem.name === 'taskTime') {
				return {
					...formItem,
					value: expireTime ? moment.utc(fillInfo.expiresAt).format('HH:mm') : ''
				};
			}

			return {
				...formItem,
				value: fillInfo[editMapping[formItem.name]]
			};
		}
		return formItem;
	});

	return filledCustomer.map((formItem) => {
		if (Object.prototype.hasOwnProperty.call(editMappingExtras, formItem.name)) {
			if (fillInfo.aditionalFields) {
				const filledObject = {
					...formItem,
					value: fillInfo.aditionalFields[editMappingExtras[formItem.name]]
				};

				if (filledObject.name === nextSelect) filledObject.hidden = false;

				if (filledObject.matching && filledObject.value) {
					nextSelect = filledObject.matching[filledObject.value];
				}

				return filledObject;
			}
			return formItem;
		}
		return formItem;
	});
};

export const verifyTaskExtrasBeforeEnd = (fillInfo, formControls) => {
	const constrols = fillTasks(fillInfo, formControls);
	let filteredControls = constrols.map((control) => {
		if (control.value !== '') {
			return {
				...control,
				hidden: false
			};
		}
		return control;
	});

	filteredControls = filteredControls.filter(field => (
		!field.hidden && field.extras
	));

	let isFieldsValid = true;
	filteredControls.forEach((field) => {
		isFieldsValid = field.value !== '' && isFieldsValid;
	});

	return isFieldsValid;
};

export const parseFormItem = (formControls, formItem, removedField) => {
	const {
		wasTouched, value, isValid, name, isMultiple
	} = formItem;
	if ((wasTouched && isValid && value !== '') || (removedField && isMultiple)) {
		if (isMultiple) {
			const fieldValues = [];
			formControls.forEach((control) => {
				if (control.name === name) {
					fieldValues.push(control.value);
				}
			});
			return fieldValues;
		}

		return value;
	}

	return null;
};

export const getTimezone = () => new Date().toString().split('GMT')[1].split(' (')[0];

export const verifyIfHasActiveChat = (interactions) => {
	const activeInteractions = interactions.filter((interactionItem) => {
		const { currentState, interactionType } = interactionItem;
		const ignoreTypes = ['PHONE', 'PHONE_CONF', 'OFFCONTACT'];
		const ignoreStates = ['MISSED', 'REPLYING', 'NOT_ANSWER'];
		return !ignoreStates.includes(currentState) && !ignoreTypes.includes(interactionType);
	});

	return activeInteractions.length > 0;
};

export const verifyPermissions = (permissions, id) => {
	const permissionItem = permissions && permissions.find(permission => permission === id);

	return !!permissionItem;
};

export const killSession = (type, status, body, hasAuth0 = false) => {
	const executekillSession = (info) => {
		window.localStorage.setItem(info, true);
		window.sessionStorage.clear();
		if (!hasAuth0) {
			window.location.reload();
		}
	};

	if (status !== 200 && body && body.logout) {
		executekillSession(type);
	} else if (type === 'kill' || type === 'killBreak') {
		executekillSession(type);
	}
};

export const placeCaretAtEnd = (input) => {
	input.focus();
	if (typeof window.getSelection !== 'undefined' && typeof document.createRange !== 'undefined') {
		const range = document.createRange();
		range.selectNodeContents(input);
		range.collapse(false);
		const sel = window.getSelection();
		sel.removeAllRanges();
		sel.addRange(range);
	} else if (typeof document.body.createTextRange !== 'undefined') {
		const textRange = document.body.createTextRange();
		textRange.moveToElementText(input);
		textRange.collapse(false);
		textRange.select();
	}
};

export const useMountEffect = func => useEffect(func, []);

export const usePrevious = (value) => {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	});

	return ref.current;
};

export const getInteractionsCountDiff = (
	state,
	currentInteractions,
	currentPendingInteractions,
	currentMissedInteractions
) => {
	const count = { ...state.count };

	if (currentInteractions) {
		const prevInteractionsLength = state.interactions.filter(({ currentState }) => (
			QUEUE_INTERACTION_STATES.includes(currentState)
		)).length;
		const interactionsLength = currentInteractions.filter(
			({ currentState }) => QUEUE_INTERACTION_STATES.includes(currentState)
		).length;
		const interactionsLengthDiff = interactionsLength - prevInteractionsLength;
		count.queue = state.count.queue + interactionsLengthDiff;
	}

	if (currentPendingInteractions) {
		const prevPendingInteractionsLength = state.pendingInteractions.length;
		const pendingInteractionsLengthDiff = currentPendingInteractions.length
			- prevPendingInteractionsLength;
		count.inbox = state.count.inbox + pendingInteractionsLengthDiff;
	}

	if (currentMissedInteractions) {
		const prevMissedInteractionsLength = state.missedInteractions.length;
		const missedInteractionsLengthDiff = currentMissedInteractions.length
			- prevMissedInteractionsLength;
		count.missed = state.count.missed + missedInteractionsLengthDiff;
	}

	return count;
};

export const filterArray = (array, parameter, value, byEqual = false) => {
	if (byEqual) return array.filter(arrayItem => arrayItem[parameter] === value);
	return array.filter(arrayItem => arrayItem[parameter] !== value);
};

export const letSendAttachment = (attachmentSize, attachmentType, interactionType) => {
	const typesAndSizesInMB = [
		{ type: 'audio', size: 16 },
		{ type: 'video', size: 16 },
		{ type: 'image', size: 5 }
	];
	const limitByType = typesAndSizesInMB.find(({ type }) => attachmentType.match(type));
	const sizeConvertedToMB = attachmentSize / 1000000;

	if (interactionType === 'WHATSAPP' && limitByType) return { approved: limitByType.size >= sizeConvertedToMB, attachmentLimit: limitByType };
	return { approved: sizeConvertedToMB < 100, attachmentLimit: { type: 'any', size: 100 } };
};

export const checkFeature = (features, identifier) => features.some(({ name, status }) => name === identifier && status === 'true');

export const isZenviaDomain = () => window.location.host.includes('zenvia') || window.location.hostname.includes('zenvia');

export const isLocal = () => window.location.hostname.includes('localhost');

export const isAuth0Enabled = () => parseInt(process.env.OMZ_ENABLE_AUTH0) > 0;

export const isZenviaEmail = email => email.includes('zenvia');

export const writeUTFBytes = (view, offset, string) => {
	const lng = string.length;
	for (let i = 0; i < lng; i += 1) {
		view.setUint8(offset + i, string.charCodeAt(i));
	}
};

export const createWAVFile = (interleavedChannels, sampleRate) => {
	const buffer = new ArrayBuffer(44 + interleavedChannels.length * 2);
	const dataView = new DataView(buffer);
	// RIFF chunk descriptor
	writeUTFBytes(dataView, 0, 'RIFF');
	dataView.setUint32(4, 44 + interleavedChannels.length * 2, true);
	writeUTFBytes(dataView, 8, 'WAVE');
	// FMT sub-chunk
	writeUTFBytes(dataView, 12, 'fmt ');
	dataView.setUint32(16, 16, true);
	dataView.setUint16(20, 1, true);
	// stereo (2 channels)
	dataView.setUint16(22, 2, true);
	dataView.setUint32(24, sampleRate, true);
	dataView.setUint32(28, sampleRate * 4, true);
	dataView.setUint16(32, 4, true);
	dataView.setUint16(34, 16, true);
	// data sub-chunk
	writeUTFBytes(dataView, 36, 'data');
	dataView.setUint32(40, interleavedChannels.length, true);

	// write the PCM samples
	const lng = interleavedChannels.length;
	let index = 44;
	const volume = 1;
	for (let i = 0; i < lng; i += 2) {
		dataView.setInt16(index, interleavedChannels[i] * (0x7fff * volume), true);
		index += 2;
	}

	return { dataView, buffer };
};

export const convertWAVtoMP3 = (dataView, buffer) => {
	const wavHdr = lamejs.WavHeader.readHeader(dataView);
	const wavSamples = new Int16Array(buffer, wavHdr.dataOffset, wavHdr.dataLen / 2);
	const mp3Buffer = [];
	const channels = 1;
	const bitrate = 128;
	const samplesPerFrame = 1152;
	const mp3enc = new lamejs.Mp3Encoder(channels, wavHdr.sampleRate, bitrate);
	let remaining = wavSamples.length;

	for (let i = 0; remaining >= samplesPerFrame; i += samplesPerFrame) {
		const mono = wavSamples.subarray(i, i + samplesPerFrame);
		const mp3buf = mp3enc.encodeBuffer(mono);
		if (mp3buf.length > 0) {
			mp3Buffer.push(new Int8Array(mp3buf));
		}
		remaining -= samplesPerFrame;
	}

	const d = mp3enc.flush();
	if (d.length > 0) {
		mp3Buffer.push(new Int8Array(d));
	}

	return mp3Buffer;
};

export const normalizeMimeType = (fileName, type) => {
	if (fileName.includes('.zip')) {
		return mime.lookup(fileName);
	}
	if (fileName.includes('.ofx')) {
		return 'application/octet-stream';
	}
	return type;
};

export const timeForSlaToExpire = (time, startedAt, endedAt) => moment(startedAt).add({ seconds: time }).diff(endedAt ? moment(endedAt) : moment(), 'seconds');

export const sortSlasByExpiredTime = slas => slas && slas.sort((x, y) => timeForSlaToExpire(x.slaTime, x.slaStartedAt, x.slaEndedAt) - timeForSlaToExpire(y.slaTime, y.slaStartedAt, y.slaEndedAt));

export const sortInteractionsBySla = (interactions, queueType = '') => {
	const interactionWithExpiredSlas = interactions.map(interaction => ({
		...interaction,
		slas: sortSlasByExpiredTime(interaction.slas) || []
	}));

	const interactionsSortedBySla = interactionWithExpiredSlas.sort((x, y) => {
		const slaY = y.slas.find(sla => !sla.slaEndedAt);
		const slaX = x.slas.find(sla => !sla.slaEndedAt);

		if (queueType === 'queue' && (x.department.tier > 1 || y.department.tier > 1)) {
			return y.department.tier - x.department.tier;
		}

		if (!slaX || !slaY) {
			return 0;
		}

		return timeForSlaToExpire(slaX.slaTime, slaX.slaStartedAt, slaX.slaEndedAt) - timeForSlaToExpire(slaY.slaTime, slaY.slaStartedAt, slaY.slaEndedAt);
	});

	return interactionsSortedBySla;
};
