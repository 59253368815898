import axios from 'axios';
import moment from 'moment';
import * as actionTypes from './actionTypes';
import { getTimezone } from '../../shared/utility';

// INTERACTION
export const fetchInboxInteractionsBegin = page => ({
	type: actionTypes.FETCH_INBOX_INTERACTIONS_BEGIN,
	payload: page
});
export const fetchInboxInteractionsFailed = () => ({
	type: actionTypes.FETCH_INBOX_INTERACTIONS_FAILED
});
export const fetchInboxInteractionsSuccess = interactions => ({
	type: actionTypes.FETCH_INBOX_INTERACTIONS_SUCCESS,
	payload: interactions
});
export const resetInboxInteractionPageCount = () => ({
	type: actionTypes.RESET_INBOX_INTERACTION_PAGE_COUNT
});
export const fetchInboxInteractions = (page = 1, resetPageCount = false) => (
	(dispatch) => {
		if (resetPageCount) dispatch(resetInboxInteractionPageCount());
		dispatch(fetchInboxInteractionsBegin(page));
		return axios.get(`${process.env.OMZ_INTERACTIONS}/inbox?timestamp=${Date.now()}&limit=20&page=${page}&origin=uai`, { dispatch })
			.then(({ data }) => {
				const { status, success, interactions } = data;
				if (status === 200 && success) {
					dispatch(fetchInboxInteractionsSuccess(interactions));
				} else {
					dispatch(fetchInboxInteractionsFailed());
				}
			}).catch(() => {
				dispatch(fetchInboxInteractionsFailed());
			});
	}
);

export const fetchMissedInteractionsBegin = page => ({
	type: actionTypes.FETCH_MISSED_INTERACTIONS_BEGIN,
	payload: page
});
export const fetchMissedInteractionsFailed = () => ({
	type: actionTypes.FETCH_MISSED_INTERACTIONS_FAILED
});
export const fetchMissedInteractionsSuccess = interactions => ({
	type: actionTypes.FETCH_MISSED_INTERACTIONS_SUCCESS,
	payload: interactions
});
export const resetMissedInteractionPageCount = () => ({
	type: actionTypes.RESET_MISSED_INTERACTION_PAGE_COUNT
});
export const fetchMissedInteractions = (page = 1, resetPageCount = false) => (
	(dispatch) => {
		if (resetPageCount) dispatch(resetMissedInteractionPageCount());
		dispatch(fetchMissedInteractionsBegin(page));
		return axios.get(`${process.env.OMZ_INTERACTIONS}/missed?timestamp=${Date.now()}&limit=20&page=${page}&origin=uai`, { dispatch })
			.then(({ data }) => {
				const { status, success, interactions } = data;

				if (status === 200 && success) {
					dispatch(fetchMissedInteractionsSuccess(interactions));
				} else {
					dispatch(fetchMissedInteractionsFailed());
				}
			}).catch(() => {
				dispatch(fetchMissedInteractionsFailed());
			});
	}
);

export const fetchInteractionsCountBegin = () => ({
	type: actionTypes.FETCH_INTERACTIONS_COUNT_BEGIN
});
export const fetchInteractionsCountSuccess = countInfo => ({
	type: actionTypes.FETCH_INTERACTIONS_COUNT_SUCCESS,
	payload: countInfo
});
export const fetchInteractionsCountFailed = () => ({
	type: actionTypes.FETCH_INTERACTIONS_COUNT_FAILED
});
export const fetchInteractionsCount = () => (
	(dispatch) => {
		dispatch(fetchInteractionsCountBegin());
		return axios.get(`${process.env.OMZ_INTERACTIONS}/count?timestamp=${Date.now()}&origin=uai`, { dispatch })
			.then(({ data }) => {
				const {
					status,
					success,
					inbox,
					queue,
					missed
				} = data;

				if (status === 200 && success) {
					dispatch(fetchInteractionsCountSuccess({ inbox, queue, missed }));
				} else {
					dispatch(fetchInteractionsCountFailed());
				}
			}).catch(() => {
				dispatch(fetchInteractionsCountFailed());
			});
	}
);

export const fetchQueueInteractionsBegin = page => ({
	type: actionTypes.FETCH_QUEUE_INTERACTIONS_BEGIN,
	payload: page
});
export const fetchQueueInteractionsFailed = () => ({
	type: actionTypes.FETCH_QUEUE_INTERACTIONS_FAILED
});
export const fetchQueueInteractionsSuccess = interactions => ({
	type: actionTypes.FETCH_QUEUE_INTERACTIONS_SUCCESS,
	payload: interactions
});
export const resetQueueInteractionPageCount = () => ({
	type: actionTypes.RESET_QUEUE_INTERACTION_PAGE_COUNT
});
export const fetchQueueInteractions = (page = 1, resetPageCount = false, initial) => (
	(dispatch) => {
		if (resetPageCount) dispatch(resetQueueInteractionPageCount());
		dispatch(fetchQueueInteractionsBegin(page));
		return axios.get(`${process.env.OMZ_INTERACTIONS}/queue?timestamp=${Date.now()}&limit=20&page=${page}&origin=uai`, { dispatch })
			.then(({ data }) => {
				const { status, success, interactions } = data;
				if (status === 200 && success) {
					if (initial) dispatch(fetchInteractionsCount());
					dispatch(fetchQueueInteractionsSuccess(interactions));
				} else {
					dispatch(fetchQueueInteractionsFailed());
				}
			}).catch(() => {
				dispatch(fetchQueueInteractionsFailed());
			});
	}
);

export const searchInboxInteractionsBegin = () => ({
	type: actionTypes.SEARCH_INBOX_INTERACTIONS_BEGIN
});
export const searchInboxInteractionsSuccess = interactions => ({
	type: actionTypes.SEARCH_INBOX_INTERACTIONS_SUCCESS,
	payload: interactions
});
export const searchInboxInteractionsFailed = () => ({
	type: actionTypes.SEARCH_INBOX_INTERACTIONS_FAILED
});
export const searchInboxInteractions = search => (
	(dispatch) => {
		dispatch(searchInboxInteractionsBegin());
		return axios.get(`${process.env.OMZ_INTERACTIONS}/inbox?timestamp=${Date.now()}&limit=20&page=1&search=${search}`, { dispatch })
			.then(({ data }) => {
				const { status, success, interactions } = data;
				if (status === 200 && success) {
					dispatch(searchInboxInteractionsSuccess(interactions));
				} else {
					dispatch(searchInboxInteractionsFailed());
				}
			}).catch(() => {
				dispatch(searchInboxInteractionsFailed());
			});
	}
);

export const searchQueueInteractionsBegin = () => ({
	type: actionTypes.SEARCH_QUEUE_INTERACTIONS_BEGIN
});
export const searchQueueInteractionsSuccess = interactions => ({
	type: actionTypes.SEARCH_QUEUE_INTERACTIONS_SUCCESS,
	payload: interactions
});
export const searchQueueInteractionsFailed = () => ({
	type: actionTypes.SEARCH_QUEUE_INTERACTIONS_FAILED
});
export const searchQueueInteractions = search => (
	(dispatch) => {
		dispatch(searchQueueInteractionsBegin());
		return axios.get(`${process.env.OMZ_INTERACTIONS}/queue?timestamp=${Date.now()}&limit=20&page=1&search=${search}`, { dispatch })
			.then(({ data }) => {
				const { status, success, interactions } = data;
				if (status === 200 && success) {
					dispatch(searchQueueInteractionsSuccess(interactions));
				} else {
					dispatch(searchQueueInteractionsFailed());
				}
			}).catch(() => {
				dispatch(searchQueueInteractionsFailed());
			});
	}
);

export const searchMissedInteractionsBegin = () => ({
	type: actionTypes.SEARCH_MISSED_INTERACTIONS_BEGIN
});
export const searchMissedInteractionsSuccess = interactions => ({
	type: actionTypes.SEARCH_MISSED_INTERACTIONS_SUCCESS,
	payload: interactions
});
export const searchMissedInteractionsFailed = () => ({
	type: actionTypes.SEARCH_MISSED_INTERACTIONS_FAILED
});
export const searchMissedInteractions = search => (
	(dispatch) => {
		dispatch(searchMissedInteractionsBegin());
		return axios.get(`${process.env.OMZ_INTERACTIONS}/missed?timestamp=${Date.now()}&limit=20&page=1&search=${search}`, { dispatch })
			.then(({ data }) => {
				const { status, success, interactions } = data;
				if (status === 200 && success) {
					dispatch(searchMissedInteractionsSuccess(interactions));
				} else {
					dispatch(searchMissedInteractionsFailed());
				}
			}).catch(() => {
				dispatch(searchMissedInteractionsFailed());
			});
	}
);

export const defineSelectedInteractionSuccess = interactionHash => ({
	type: actionTypes.DEFINE_SELECTED_INTERACTION,
	payload: interactionHash
});
export const defineSelectedInteraction = interactionHash => (
	(dispatch) => {
		dispatch(defineSelectedInteractionSuccess(interactionHash));
	}
);

export const setCurrentInteractionSuccess = interaction => ({
	type: actionTypes.SET_CURRENT_INTERACTION,
	payload: interaction
});
export const setCurrentInteraction = interaction => (
	(dispatch) => {
		dispatch(setCurrentInteractionSuccess(interaction));
	}
);

export const updateNoteBegin = () => ({
	type: actionTypes.UPDATE_NOTE_BEGIN
});
export const updateNoteSuccess = (note, interactionHash) => ({
	type: actionTypes.UPDATE_NOTE_SUCCESS,
	payload: {
		note,
		interactionHash
	}
});
export const updateNoteFailed = () => ({
	type: actionTypes.UPDATE_NOTE_FAILED
});
export const updateNoteChanged = () => (
	dispatch => dispatch({
		type: actionTypes.UPDATE_NOTE_CHANGED
	})
);
export const updateNote = interactionInfo => (
	(dispatch) => {
		const {
			agentId, interactionHash, note
		} = interactionInfo;
		dispatch(updateNoteBegin());
		return fetch(`${process.env.OMZ_ZAPI}/admin/interactions/${interactionHash}`, {
			method: 'PUT',
			headers: {
				Authorization: sessionStorage.getItem('omz_token'),
				'Content-type': 'application/json'
			},
			body: JSON.stringify({
				agentId,
				note
			})
		})
			.then((res) => {
				if (res.ok) return res.json();
				return {};
			}).then((json) => {
				if (json.status === 200 && json.success) {
					dispatch(updateNoteSuccess(note, interactionHash));
				} else {
					dispatch(updateNoteFailed());
				}
			}).catch(() => {
				dispatch(updateNoteFailed());
			});
	}
);

export const clearNoteAttachment = ({ agentId, interactionHash, note }) => (
	(dispatch) => {
		dispatch(
			updateNote({
				note: {
					...note,
					s3: null,
					url: null
				},
				agentId,
				interactionHash
			})
		);
	}
);
export const sendNoteAttachment = ({
	formData,
	agentId,
	interactionHash,
	note
}) => (
	(dispatch) => {
		dispatch(updateNoteBegin());
		fetch(`${process.env.OMZ_ZAPI}/admin/uploads`, {
			method: 'POST',
			headers: { Authorization: sessionStorage.getItem('omz_token') },
			'Content-type': 'multipart/form-data',
			body: formData
		}).then((res) => {
			if (res.ok) return res.json();
			return {};
		}).then((json) => {
			if (json.status === 200) {
				const {
					key, bucket, filename, url
				} = JSON.parse(json.files[0]);

				dispatch(
					updateNote({
						note: {
							...note,
							s3: { key, bucket, filename },
							url
						},
						agentId,
						interactionHash
					})
				);
			} else {
				dispatch(updateNoteFailed());
			}
		}).catch(() => {
			dispatch(updateNoteFailed());
		});
	}
);

export const onNewWhatsappVoiceCallSuccess = callDetails => ({
	type: actionTypes.ON_NEW_WHATSAPP_VOICE_CALL_SUCCESS,
	payload: callDetails
});

export const onNewWhatsappVoiceCall = callDetails => (
	(dispatch) => {
		dispatch(onNewWhatsappVoiceCallSuccess(callDetails));
	}
);

const onWhatsappCallDurationSuccess = callDuration => ({
	type: actionTypes.ON_WHATSAPP_CALL_DURATION_SUCCESS,
	payload: callDuration
});

export const onWhatsappCallDuration = callDuration => (
	(dispatch) => {
		dispatch(onWhatsappCallDurationSuccess(callDuration));
	}
);

export const updateCallDuration = callDuration => (dispatch) => {
	dispatch({
		type: actionTypes.UPDATE_CALL_DURATION,
		payload: callDuration
	});
};

export const onNewWhatsappMediaStreamSuccess = mediaStreamDetails => ({
	type: actionTypes.ON_NEW_WHATSAPP_MEDIA_STREAM,
	payload: mediaStreamDetails
});

export const onNewWhatsappMediaStream = mediaStreamDetails => (
	(dispatch) => {
		dispatch(onNewWhatsappMediaStreamSuccess(mediaStreamDetails));
	}
);

export const onTerminateWhatsappVoiceCallSuccess = callDetails => ({
	type: actionTypes.ON_TERMINATE_WHATSAPP_VOICE_CALL_SUCCESS,
	payload: callDetails
});

export const onTerminateWhatsappVoiceCall = callDetails => (
	(dispatch) => {
		dispatch(onTerminateWhatsappVoiceCallSuccess(callDetails));
	});

export const updateTagsBegin = () => ({
	type: actionTypes.UPDATE_TAGS_BEGIN
});
export const updateTagsSuccess = (tags, interactionHash) => ({
	type: actionTypes.UPDATE_TAGS_SUCCESS,
	payload: {
		tags,
		interactionHash
	}
});
export const updateTagsFailed = () => ({
	type: actionTypes.UPDATE_TAGS_FAILED
});
export const updateTagsChanged = () => (
	dispatch => dispatch({
		type: actionTypes.UPDATE_TAGS_CHANGED
	})
);
export const updateTags = interactionInfo => (
	(dispatch) => {
		const {
			agentId, interactionHash, tags
		} = interactionInfo;
		const tagIds = [];
		tags.forEach(tag => (tagIds.push(tag.id)));

		dispatch(updateTagsBegin());
		return fetch(`${process.env.OMZ_ZAPI}/admin/interactions/${interactionHash}`, {
			method: 'PUT',
			headers: {
				Authorization: sessionStorage.getItem('omz_token'),
				'Content-type': 'application/json'
			},
			body: JSON.stringify({
				agentId,
				tagIds
			})
		})
			.then(res => res.json())
			.then((json) => {
				if (json.status === 200 && json.success) {
					dispatch(updateTagsSuccess(tags, interactionHash));
				} else {
					dispatch(updateTagsFailed());
				}
			}).catch(() => {
				dispatch(updateTagsFailed());
			});
	}
);

export const fetchTransferDataBegin = () => ({
	type: actionTypes.FETCH_TRANSFER_DATA_BEGIN
});
export const fetchTransferDataSuccess = (transferData, page) => ({
	type: actionTypes.FETCH_TRANSFER_DATA_SUCCESS,
	payload: { transferData, page }
});
export const fetchTransferDataFailed = () => ({
	type: actionTypes.FETCH_TRANSFER_DATA_FAILED
});
export const fetchTransferData = ({
	accountId,
	departmentId,
	page = 1,
	search
}) => (
	(dispatch) => {
		let url = `${process.env.OMZ_ZAPI}/admin/accounts/${accountId}/transference?timestamp=${new Date().getTime()}`;

		if (page) url += `&page=${page}`;
		if (search) url += `&search=${search}`;
		if (departmentId) url += `&departmentId=${departmentId}`;

		dispatch(fetchTransferDataBegin());
		return axios.get(url, { dispatch })
			.then(({ data }) => {
				const { status, message, result } = data;
				if (status === 200 && message === 'Success') {
					dispatch(fetchTransferDataSuccess(result, page));
				} else {
					dispatch(fetchTransferDataFailed());
				}
			}).catch(() => {
				dispatch(fetchTransferDataFailed());
			});
	}
);

export const loadInteractionInfoBegin = interactionHash => ({
	type: actionTypes.LOAD_INTERACTION_INFO_BEGIN,
	payload: interactionHash
});
export const loadInteractionInfoSuccess = (interactionHash, interactionInfo) => ({
	type: actionTypes.LOAD_INTERACTION_INFO_SUCCESS,
	payload: { interactionHash, interactionInfo }
});
export const loadInteractionInfoFailed = interactionHash => ({
	type: actionTypes.LOAD_INTERACTION_INFO_FAILED,
	payload: interactionHash
});
export const loadInteractionInfo = (accountId, interactionHash) => (
	(dispatch) => {
		dispatch(loadInteractionInfoBegin(interactionHash));
		return axios.get(`${process.env.OMZ_INTERACTIONS}/info/${interactionHash}`, { dispatch })
			.then((response) => {
				const { status, data } = response.data;
				if (status !== 200) {
					dispatch(loadInteractionInfoFailed(interactionHash));
				} else {
					dispatch(loadInteractionInfoSuccess(interactionHash, data));
				}
			}).catch(() => {
				dispatch(loadInteractionInfoFailed(interactionHash));
			});
	}
);

export const fetchInteractionHistoryBegin = () => ({
	type: actionTypes.FETCH_INTERACTION_HISTORY_BEGIN
});
export const fetchInteractionHistorySuccess = (historyInteraction, interactionHash) => ({
	type: actionTypes.FETCH_INTERACTION_HISTORY_SUCCESS,
	payload: {
		historyInteraction,
		interactionHash
	}
});
export const fetchInteractionHistoryFailed = () => ({
	type: actionTypes.FETCH_INTERACTION_HISTORY_FAILED
});
export const fetchInteractionHistory = requestInfo => (
	(dispatch) => {
		const { interactionHash } = requestInfo;
		dispatch(fetchInteractionHistoryBegin());
		return axios.get(`${process.env.OMZ_INTERACTIONS}/info/${interactionHash}`, { dispatch })
			.then((response) => {
				const { status, success, data } = response.data;
				if (status === 200 && success) {
					dispatch(fetchInteractionHistorySuccess(data, interactionHash));
				} else {
					dispatch(fetchInteractionHistoryFailed());
				}
			}).catch(() => {
				dispatch(fetchInteractionHistoryFailed());
			});
	}
);

export const removingInteraction = interactionHash => ({
	type: actionTypes.REMOVE_INTERACTION,
	payload: interactionHash
});
export const removeInteraction = interactionHash => (
	dispatch => dispatch(removingInteraction(interactionHash))
);

export const acceptInteractionBegin = interactionHash => ({
	type: actionTypes.ON_ACCEPT_INTERACTION_BEGIN,
	payload: interactionHash
});
export const acceptInteraction = interactionHash => (
	(dispatch) => {
		window.omzVish.acceptInteraction(interactionHash);
		dispatch(acceptInteractionBegin(interactionHash));
	}
);
export const onAcceptInteractionSuccess = interactionHash => ({
	type: actionTypes.ON_ACCEPT_INTERACTION_SUCCESS,
	payload: interactionHash
});
export const onAcceptedInteraction = (interactionHash, status) => (
	(dispatch) => {
		if (status === 'ok') {
			dispatch(onAcceptInteractionSuccess(interactionHash));
		} else {
			dispatch(removeInteraction({ interactionHash }));
		}
	}
);

export const replyInteractionBegin = interactionHash => ({
	type: actionTypes.ON_REPLY_INTERACTION_SUCCESS,
	payload: interactionHash
});
export const replyInteraction = interactionHash => (
	(dispatch) => {
		window.omzVish.replyInteraction(interactionHash);
		dispatch(replyInteractionBegin(interactionHash));
	}
);

export const onNewInteractionSuccess = interaction => ({
	type: actionTypes.ON_NEW_INTERACTION_SUCCESS,
	payload: interaction
});
export const onNewInteraction = interaction => (
	(dispatch) => {
		dispatch(onNewInteractionSuccess(interaction));
	}
);

export const onUpdateInteractionSuccess = (interaction, agent) => ({
	type: actionTypes.ON_UPDATE_INTERACTION_SUCCESS,
	payload: {
		interaction,
		agent
	}

});
export const onUpdateInteraction = (interaction, agent) => (
	dispatch => dispatch(onUpdateInteractionSuccess(interaction, agent))
);

export const setPendingMessages = interactionHash => ({
	type: actionTypes.SET_PENDING_MESSAGES,
	payload: interactionHash
});
export const clearPendingMessages = interactionHash => ({
	type: actionTypes.CLEAR_PENDING_MESSAGES,
	payload: interactionHash
});

export const onSendMessageSuccess = (receivedMessage, interactionHash) => ({
	type: actionTypes.ON_SEND_MESSAGE_SUCCESS,
	payload: {
		receivedMessage,
		interactionHash
	}
});
export const onSendMessage = (receivedMessage, interactionHash) => (
	(dispatch) => {
		dispatch(onSendMessageSuccess(receivedMessage, interactionHash));
	}
);

export const onDeliveredMessageSuccess = deliveredMessage => ({
	type: actionTypes.ON_DELIVERED_MESSAGE_SUCCESS,
	payload: deliveredMessage
});
export const onDeliveredMessage = deliveredMessage => (
	(dispatch) => {
		dispatch(onDeliveredMessageSuccess(deliveredMessage));
	}
);

export const onReceivedMessageSuccess = receivedMessage => ({
	type: actionTypes.ON_RECEIVED_MESSAGE_SUCCESS,
	payload: receivedMessage
});
export const onReceivedMessage = receivedMessage => (
	(dispatch) => {
		dispatch(onReceivedMessageSuccess(receivedMessage));
	}
);

export const onTypingSuccess = interactionHash => ({
	type: actionTypes.ON_TYPING_SUCCESS,
	payload: interactionHash
});
export const onTyping = interactionHash => (
	(dispatch) => {
		dispatch(onTypingSuccess(interactionHash));
	}
);

export const onClearedSuccess = interactionHash => ({
	type: actionTypes.ON_CLEARED_SUCCESS,
	payload: interactionHash
});
export const onCleared = interactionHash => (
	(dispatch) => {
		dispatch(onClearedSuccess(interactionHash));
	}
);

export const onTransferredSuccess = interactionHash => ({
	type: actionTypes.ON_TRANSFERRED_SUCCESS,
	payload: interactionHash
});
export const onTransferred = interactionHash => (
	(dispatch) => {
		dispatch(onTransferredSuccess(interactionHash));
	}
);

export const onFinishedSuccess = (interactionHash, expired, removeThisInteraction) => ({
	type: actionTypes.ON_FINISHED_SUCCESS,
	payload: {
		interactionHash,
		expired,
		removeThisInteraction
	}
});
export const onFinished = ({
	interactionHash, expired, removeThisInteraction
}) => (
	(dispatch) => {
		dispatch(onFinishedSuccess(interactionHash, expired, removeThisInteraction));
	}
);

export const onFinishing = (interactionHash, removeThisInteraction, agentName) => ({
	type: actionTypes.ON_FINISHING,
	payload: {
		interactionHash,
		removeThisInteraction,
		agentName
	}
});
export const onFinish = ({
	interactionHash,
	media,
	removeThisInteraction,
	agentName
}) => (
	(dispatch) => {
		const options = {};
		window.omzVish.finishInteraction(interactionHash, media, options);
		if (interactionHash === window.ttvSdk.currentInteraction) window.ttvSdk.hangup();
		dispatch(onFinishing(interactionHash, removeThisInteraction, agentName));
	}
);

export const onClosingInteraction = interactionHash => ({
	type: actionTypes.ON_CLOSING_INTERACTION,
	payload: interactionHash
});
export const onCloseInteraction = requestInfo => (
	(dispatch) => {
		const { interactionHash } = requestInfo;
		window.omzVish.closeInteraction(interactionHash);
		dispatch(defineSelectedInteractionSuccess(''));
		dispatch(removeInteraction(requestInfo));
	}
);

export const onNewMessageSuccess = (newMessage, interactionHash) => ({
	type: actionTypes.ON_NEW_MESSAGE_SUCCESS,
	payload: {
		newMessage,
		interactionHash
	}
});
export const onNewMessage = (newMessage, interactionHash) => (
	(dispatch) => {
		dispatch(onNewMessageSuccess(newMessage, interactionHash));
		dispatch(onClearedSuccess(interactionHash));

		if (newMessage.origin === 'CLIENT') dispatch(setPendingMessages(interactionHash));
	}
);

// CUSTOMER
export const updateCustomerBegin = () => ({
	type: actionTypes.UPDATE_CUSTOMER_BEGIN
});
export const updateCustomerSuccess = updatedCustomer => ({
	type: actionTypes.UPDATE_CUSTOMER_SUCCESS,
	payload: updatedCustomer
});
export const updateCustomerFailed = errors => ({
	type: actionTypes.UPDATE_CUSTOMER_FAILED,
	payload: errors
});
export const updateCustomerClear = () => ({
	type: actionTypes.UPDATE_CUSTOMER_CLEAR
});
export const updateCustomer = (customerInfo) => {
	const {
		customerKey, fields, reload, fetchAgentContacts, note
	} = customerInfo;
	return (dispatch) => {
		dispatch(updateCustomerBegin());
		return fetch(`${process.env.OMZ_ZAPI}/admin/customers/${customerKey}`, {
			method: 'PUT',
			headers: {
				Authorization: sessionStorage.getItem('omz_token'),
				'Content-type': 'application/json'
			},
			body: JSON.stringify({
				customerKey,
				fields,
				note
			})
		})
			.then(res => res.json())
			.then((json) => {
				const { customer, status } = json;
				if (status === 200 && customer) {
					const updatedCustomer = {
						fields: customer.fields,
						customerKey: customer.customerKey,
						note: customer.note
					};
					dispatch(updateCustomerSuccess(updatedCustomer));
					if (reload) fetchAgentContacts({ page: 1 });
				} else if (status === 422) {
					dispatch(updateCustomerFailed(json.errors));
				} else {
					dispatch(updateCustomerFailed());
				}
			}).catch(() => {
				dispatch(updateCustomerFailed());
			});
	};
};

export const updateInteractionCustomerBegin = () => ({
	type: actionTypes.UPDATE_INTERACTION_CUSTOMER_BEGIN
});
export const updateInteractionCustomerSuccess = (
	fields, interactionHash, customerKey, historyCount
) => ({
	type: actionTypes.UPDATE_INTERACTION_CUSTOMER_SUCCESS,
	payload: {
		fields,
		interactionHash,
		customerKey,
		historyCount
	}
});
export const updateInteractionCustomerFailed = () => ({
	type: actionTypes.UPDATE_INTERACTION_CUSTOMER_FAILED
});
export const updateInteractionCustomer = (requestInfo) => {
	const {
		interactionHash, customerId, fields, customerKey, historyCount
	} = requestInfo;
	return (dispatch) => {
		dispatch(updateInteractionCustomerBegin());
		return (
			fetch(`${process.env.OMZ_ZAPI}/admin/interactions/${interactionHash}`,
				{
					method: 'PUT',
					headers: {
						Authorization: sessionStorage.getItem('omz_token'),
						'Content-type': 'application/json'
					},
					body: JSON.stringify({
						customerId
					})
				})
				.then(res => res.json())
				.then((json) => {
					if (json.status === 200) {
						dispatch(updateInteractionCustomerSuccess(
							fields, interactionHash, customerKey, historyCount
						));
					} else {
						dispatch(updateInteractionCustomerFailed());
					}
				}).catch(() => {
					dispatch(updateInteractionCustomerFailed());
				})
		);
	};
};

export const fetchHistoryBegin = customerKey => ({
	type: actionTypes.FETCH_HISTORY_BEGIN,
	payload: customerKey
});
export const fetchHistorySuccess = (historyInteractions, customerKey, isSearching) => ({
	type: actionTypes.FETCH_HISTORY_SUCCESS,
	payload: {
		customerKey,
		historyInteractions,
		isSearching
	}
});
export const fetchHistoryFailed = () => ({
	type: actionTypes.FETCH_HISTORY_FAILED
});
export const fetchHistory = requestInfo => (
	(dispatch) => {
		const {
			customerKey,
			page = 1,
			search = ''
		} = requestInfo;

		let fetchPage = page;
		let isSearching = false;
		if (search !== '') {
			isSearching = true;
			fetchPage = 1;
		}

		dispatch(fetchHistoryBegin(customerKey));
		return axios.get(`${process.env.OMZ_ZAPI}/admin/customers/${customerKey}/interactions?page=${fetchPage}&limit=20&search=${search}`, { dispatch })
			.then(({ data }) => {
				const { status, result } = data;
				if (status === 200 && result) {
					dispatch(fetchHistorySuccess(result.interactions, customerKey, isSearching));
				} else {
					dispatch(fetchHistoryFailed());
				}
			}).catch(() => {
				dispatch(fetchHistoryFailed());
			});
	}
);

export const fetchCustomerBegin = () => ({
	type: actionTypes.FETCH_CUSTOMER_BEGIN
});
export const fetchCustomerSuccess = (customers, interactionHash, customerKey) => ({
	type: actionTypes.FETCH_CUSTOMER_SUCCESS,
	payload: {
		customers,
		interactionHash,
		customerKey
	}
});
export const fetchCustomerFailed = () => ({
	type: actionTypes.FETCH_CUSTOMER_FAILED
});
export const fetchCustomer = requestInfo => (
	(dispatch) => {
		const {
			search, interactionHash, customerKey
		} = requestInfo;
		dispatch(fetchCustomerBegin());
		return axios.get(`${process.env.OMZ_ZAPI}/admin/customers?limit=20&page=1&search=${search}`, { dispatch })
			.then(({ data }) => {
				if (data.errorMessage) {
					dispatch(fetchCustomerFailed(data.errorMessage));
				} else {
					dispatch(fetchCustomerSuccess(data.customers, interactionHash, customerKey));
				}
			}).catch(() => {});
	}
);

export const sendAttachment = ({
	formData,
	agent,
	currentInteractionHash,
	fileInfo,
	referenceId
}) => (
	dispatch => (
		fetch(`${process.env.OMZ_ZAPI}/admin/uploads`, {
			method: 'POST',
			headers: { Authorization: sessionStorage.getItem('omz_token') },
			'Content-type': 'multipart/form-data',
			body: formData
		})
			.then((res) => {
				if (res.ok) return res.json();
				return {};
			}).then((json) => {
				if (json.status === 200) {
					const receivedMessage = JSON.parse(json.files[0]);
					const type = fileInfo.type || 'file';
					const formattedMessage = {
						contentType: type,
						createdAt: new Date(),
						url: receivedMessage.url,
						origin: 'agent',
						status: 'read',
						agentName: agent.info.name,
						agentPhoto: agent.info.photo,
						fileName: receivedMessage.filename,
						content: receivedMessage.filename,
						referenceId
					};

					formattedMessage.tempId = window.omzVish.newMessage(
						receivedMessage,
						currentInteractionHash,
						type,
						referenceId
					);

					dispatch(onNewMessageSuccess(formattedMessage, currentInteractionHash));
					dispatch(setPendingMessages(currentInteractionHash));
				}
			}).catch(() => {})
	));

export const sendMailMessage = (info, agent) => (
	(dispatch) => {
		window.omzVish.sendMailMessage(info);
		const { message, interactionHash, attachments } = info;
		const formattedMessage = {
			contentType: 'text',
			createdAt: new Date(),
			origin: 'agent',
			status: 'read',
			agentName: agent.info.name,
			agentPhoto: agent.info.photo,
			content: message,
			messageId: new Date().getTime(),
			tempId: new Date().getTime(),
			attachments
		};

		dispatch(onNewMessageSuccess(formattedMessage, interactionHash));
		dispatch(setPendingMessages(interactionHash));
	}
);

export const mergeCustomerBegin = () => ({
	type: actionTypes.MERGE_CUSTOMER_BEGIN
});
export const mergeCustomerSuccess = (customer, interactionHash, oldCustomerKey) => ({
	type: actionTypes.MERGE_CUSTOMER_SUCCESS,
	payload: {
		customer,
		interactionHash,
		oldCustomerKey
	}
});
export const mergeCustomerFailed = () => ({
	type: actionTypes.MERGE_CUSTOMER_FAILED
});
export const clearMergeCustomerStatus = () => ({
	type: actionTypes.MERGE_CUSTOMER_CLEAR
});
export const mergeCustomer = requestInfo => (
	(dispatch) => {
		const {
			customerKey, oldCustomerAndFields, interactionHash
		} = requestInfo;
		const { oldCustomer } = oldCustomerAndFields;
		dispatch(mergeCustomerBegin());
		return fetch(`${process.env.OMZ_ZAPI}/admin/customers/${customerKey}/merge`, {
			method: 'PUT',
			headers: {
				Authorization: sessionStorage.getItem('omz_token'),
				'Content-type': 'application/json'
			},
			body: JSON.stringify(oldCustomerAndFields)
		})
			.then(res => res.json())
			.then((json) => {
				const { success, customer, status } = json;
				if (status === 200 && success) {
					dispatch(mergeCustomerSuccess(customer, interactionHash, oldCustomer));
				} else {
					dispatch(mergeCustomerFailed());
				}
			}).catch(() => {
				dispatch(mergeCustomerFailed());
			});
	}
);

export const fetchAgentHistoryBegin = () => ({
	type: actionTypes.FETCH_AGENT_HISTORY_BEGIN
});
export const fetchAgentHistorySuccess = payload => ({
	type: actionTypes.FETCH_AGENT_HISTORY_SUCCESS,
	payload
});
export const fetchAgentHistoryFailed = () => ({
	type: actionTypes.FETCH_AGENT_HISTORY_FAILED
});
export const fetchAgentHistory = requestInfo => (
	(dispatch) => {
		const { page = 1, search = '' } = requestInfo;
		const timezone = getTimezone();
		const last730d = moment().subtract(730, 'day').format('YYYY-MM-DD[T00:00:00]');
		const locale = localStorage.getItem('locale') || 'pt';
		dispatch(fetchAgentHistoryBegin());
		return axios.get(`${process.env.OMZ_HISTORY}?page=${page}&limit=20${search ? `&search=${search}` : ''}&column=last_message_date&timezone=${timezone}&locale=${locale}&from=${last730d}`, { dispatch })
			.then(({ data }) => {
				const { success, interactions, status } = data;
				if (status === 200 && success) {
					dispatch(fetchAgentHistorySuccess({ interactions, count: page }));
				} else {
					dispatch(fetchAgentHistoryFailed());
				}
			}).catch(() => {
				dispatch(fetchAgentHistoryFailed());
			});
	}
);

export const sendEmailAttachmentBegin = () => ({
	type: actionTypes.SEND_EMAIL_ATTACHMENT_BEGIN
});
export const sendEmailAttachmentSuccess = (attachment, interactionHash) => ({
	type: actionTypes.SEND_EMAIL_ATTACHMENT_SUCCESS,
	payload: { interactionHash, attachment }
});
export const sendEmailAttachmentFailed = () => ({
	type: actionTypes.SEND_EMAIL_ATTACHMENT_FAILED
});
export const clearEmailAttachment = ({ name, interactionHash }) => (
	(dispatch) => {
		dispatch({
			type: actionTypes.SEND_EMAIL_ATTACHMENT_CLEAR,
			payload: { name, interactionHash }
		});
	}
);
export const addAttachment = ({ attachment, interactionHash }) => (
	(dispatch) => {
		dispatch({
			type: actionTypes.SEND_EMAIL_ATTACHMENT_SUCCESS,
			payload: { attachment, interactionHash }
		});
	}
);
export const sendEmailAttachment = ({ formData, interactionHash }) => (
	(dispatch) => {
		dispatch(sendEmailAttachmentBegin());
		fetch(`${process.env.OMZ_ZAPI}/admin/uploads`, {
			method: 'POST',
			headers: { Authorization: sessionStorage.getItem('omz_token') },
			'Content-type': 'multipart/form-data',
			body: formData
		}).then((res) => {
			if (res.ok) return res.json();
			return {};
		}).then((json) => {
			const { files, status } = json;
			if (status === 200) {
				const receivedMessage = JSON.parse(files[0]);
				const parsedAttachment = {
					name: receivedMessage.key,
					url: receivedMessage.url
				};
				dispatch(sendEmailAttachmentSuccess(parsedAttachment, interactionHash));
			} else {
				dispatch(sendEmailAttachmentFailed());
			}
		}).catch(() => {
			dispatch(sendEmailAttachmentFailed());
		});
	}
);

export const createdInteractionTaskClear = () => (
	dispatch => dispatch({ type: actionTypes.CREATED_INTERACTION_TASK_CLEAR })
);
export const createdInteractionTask = taskInfo => (
	dispatch => dispatch({
		type: actionTypes.CREATED_INTERACTION_TASK,
		payload: {
			task: taskInfo.payload,
			interactionHash: taskInfo.interactionHash
		}
	})
);

export const setOnlyChatOrPhone = onlyChatOrPhone => (
	dispatch => dispatch({
		type: actionTypes.SET_ONLY_CHAT_OR_PHONE,
		payload: onlyChatOrPhone
	})
);

export const updateInteractionStatus = (status, interactionHash) => (
	dispatch => dispatch({
		type: actionTypes.UPDATE_INTERACTION_STATUS,
		payload: {
			status,
			interactionHash
		}
	})
);

export const finishInteractionTask = taskId => (
	dispatch => dispatch({
		type: actionTypes.FINISH_INTERACTION_TASK,
		payload: taskId
	})
);

export const fetchTagsBegin = () => ({
	type: actionTypes.FETCH_TAGS_BEGIN
});
export const fetchTagsSuccess = payload => ({ type: actionTypes.FETCH_TAGS_SUCCESS, payload });
export const fetchTagsFailed = () => ({
	type: actionTypes.FETCH_TAGS_FAILED
});
export const fetchTags = ({
	search,
	departmentId,
	interactionHash
}) => (
	(dispatch) => {
		dispatch(fetchTagsBegin());
		return axios.get(`${process.env.OMZ_INTERACTIONS}/tags?search=${search || ''}&department_id=${departmentId}`, { dispatch })
			.then(({ data }) => {
				const { status, tags } = data;
				if (status === 200 && tags) {
					dispatch(fetchTagsSuccess({ tags, interactionHash }));
				} else {
					dispatch(fetchTagsFailed());
				}
			}).catch(() => {
				dispatch(fetchTagsFailed());
			});
	}
);

export const fetchSubtagsBegin = () => ({
	type: actionTypes.FETCH_SUBTAGS_BEGIN
});
export const fetchSubtagsSuccess = payload => ({ type: actionTypes.FETCH_SUBTAGS_SUCCESS, payload });
export const fetchSubtagsFailed = () => ({
	type: actionTypes.FETCH_SUBTAGS_FAILED
});
export const fetchSubtags = ({
	level,
	parentId,
	interactionHash
}) => (
	(dispatch) => {
		dispatch(fetchSubtagsBegin());
		return axios.get(`${process.env.OMZ_ZAPI}/chat/tags/${parentId}`, { dispatch })
			.then(({ data }) => {
				const { status, tags } = data;
				if (status === 200 && tags) {
					dispatch(fetchSubtagsSuccess({ level, tags, interactionHash }));
				} else {
					dispatch(fetchSubtagsFailed());
				}
			}).catch(() => {
				dispatch(fetchSubtagsFailed());
			});
	}
);

export const fetchAnswersBegin = () => ({
	type: actionTypes.FETCH_ANSWERS_BEGIN
});
export const fetchAnswersSuccess = payload => ({ type: actionTypes.FETCH_ANSWERS_SUCCESS, payload });
export const fetchAnswersFailed = () => ({
	type: actionTypes.FETCH_ANSWERS_FAILED
});
export const fetchAnswers = requestInfo => (
	(dispatch) => {
		const {
			accountId,
			departmentId,
			interactionHash,
			search,
			page = 1
		} = requestInfo;
		let url = `${process.env.OMZ_ZAPI}/admin/shortcuts/search/${search || ''}?accountId=${accountId}&interactionHash=${interactionHash}`;
		if (page) url += `&page=${page}`;

		if (departmentId) url += `&department_id=${departmentId}`;

		dispatch(fetchAnswersBegin());
		return axios.get(url, { dispatch })
			.then(({ data }) => {
				const { status, shortcuts } = data;
				if (status === 200 && shortcuts) {
					dispatch(fetchAnswersSuccess({ shortcuts, interactionHash, page }));
				} else {
					dispatch(fetchAnswersFailed());
				}
			}).catch(() => {
				dispatch(fetchAnswersFailed());
			});
	}
);
