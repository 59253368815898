import axios from 'axios';
import * as actionTypes from './actionTypes';

export const fetchWhatsappCallBegin = () => ({
	type: actionTypes.FETCH_WHATSAPP_CALL_BEGIN
});
export const fetchWhatsappCallSuccess = whatsappCall => ({
	type: actionTypes.FETCH_WHATSAPP_CALL_SUCCESS,
	payload: whatsappCall
});
export const fetchWhatsappCallFailed = () => ({
	type: actionTypes.FETCH_WHATSAPP_CALL_FAILED
});
export const fetchWhatsappCall = accountId => (
	(dispatch) => {
		dispatch(fetchWhatsappCallBegin());
		return axios.get(`${process.env.OMZ_ZAPI}/admin/accounts/${accountId}`, { dispatch })
			.then(({ data }) => {
				const { errorMessage, whatsappCall, whatsappCallDepartmentList } = data?.account;
				if (errorMessage) {
					dispatch(fetchWhatsappCallFailed(errorMessage));
				} else {
					dispatch(fetchWhatsappCallSuccess({ whatsappCall, whatsappCallDepartmentList }));
				}
			}).catch(() => {});
	}
);
