import {
	createStore, compose, combineReducers, applyMiddleware
} from 'redux';
import thunk from 'redux-thunk';
import * as Sentry from '@sentry/react';

import InteractionReducer from './reducers/interaction';
import agentReducer from './reducers/agent';
import dashboardReducer from './reducers/dashboard';
import filtersReducer from './reducers/filters';
import formFieldsReducer from './reducers/formFields';
import customChannelsReducer from './reducers/customChannel';
import interfaceReducer from './reducers/interface';
import widgetAndIntegrationsReducer from './reducers/widgetAndIntegration';
import whatsappCallReducer from './reducers/whatsappCall';

const rootReducer = combineReducers({
	interaction: InteractionReducer,
	agent: agentReducer,
	dashboard: dashboardReducer,
	filters: filtersReducer,
	formFields: formFieldsReducer,
	customChannels: customChannelsReducer,
	interface: interfaceReducer,
	widgetAndIntegrations: widgetAndIntegrationsReducer,
	whatsappCall: whatsappCallReducer
});

const composeEnhancers = process.env.NODE_ENV === 'local' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;
const sentryReduxEnhancer = Sentry.createReduxEnhancer({
	stateTransformer: (state) => {
		try {
			Sentry.setUser(state.agent.info);
		} catch (_) {
			Sentry.setUser({});
		}
		return state;
	}
});

const store = createStore(rootReducer, composeEnhancers(
	applyMiddleware(thunk), sentryReduxEnhancer
));

export default store;
