import React from 'react';
import PropTypes from 'prop-types';
import './index.sass';
import { defineMessages, injectIntl } from 'react-intl';

import ImgSvg from '../../Atoms/ImgSvg';

const messages = defineMessages({
	killSession: {
		id: 'app.killSession',
		defaultMessage: 'Conta acessada em outro local'
	},
	killSessionContent: {
		id: 'app.killSessionContent',
		defaultMessage: 'Não são permitidos acessos simultâneos'
	},
	killSessionByBreak: {
		id: 'app.killSessionByBreak',
		defaultMessage: 'Sessão encerrada por inatividade'
	},
	killSessionContentByBreak: {
		id: 'app.killSessionContentByBreak',
		defaultMessage: 'A sessão foi encerrada pois não houve atividade após o período de pausa definido. Acesse novamente o sistema para continuar atendendo.'
	}
});

const HasAnotherSession = ({ type = 'kill', intl }) => {
	const { formatMessage } = intl;

	const messageMap = {
		kill: {
			title: formatMessage(messages.killSession),
			message: formatMessage(messages.killSessionContent)
		},
		killBreak: {
			title: formatMessage(messages.killSessionByBreak),
			message: formatMessage(messages.killSessionContentByBreak)
		}
	};

	return (
		<div
			className="HasAnotherSession"
			data-testid="component-hasAnotherSession"
		>
			<div className="HasAnotherSession__icon">
				<ImgSvg name="times-circle" />
			</div>
			<div className="HasAnotherSession__message">
				<div className="HasAnotherSession__title">
					{messageMap[type].title}
				</div>
				<div className="HasAnotherSession__content">
					{messageMap[type].message}
				</div>
			</div>
		</div>
	);
};

HasAnotherSession.propTypes = {
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired,
	type: PropTypes.string
};

export default (injectIntl(HasAnotherSession));
