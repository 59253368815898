/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
	useRef,
	useState,
	useEffect,
	useCallback
} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';

import './index.sass';
import {
	verifyPermissions,
	useMountEffect,
	usePrevious,
	letSendAttachment
} from '../../../shared/utility';
import * as actions from '../../../store/actions';
import ChatText from '../../Molecules/Inputs/ChatEmail';
import Send from '../../Molecules/Send';
import Annotation from '../../Molecules/Annotation';
import Answers from '../../Molecules/Answers';
import TagItem from '../../Molecules/TagItem';
import Tags from '../Tags';
import ChatButton from '../../Molecules/Buttons/ChatButton';
import ImgSvg from '../../Atoms/ImgSvg';
import Spinner from '../../Atoms/Spinner';
import AttachmentItem from '../../Molecules/AttachmentItem';
import Text from '../../Atoms/Text';

const messages = defineMessages({
	copyInfo: {
		id: 'chatMail.copyInfo',
		defaultMessage: '(Adicionar cópia, separar emails com vírgula)'
	},
	subject: {
		id: 'chatMail.subject',
		defaultMessage: 'Assunto'
	},
	type: {
		id: 'chatMail.type',
		defaultMessage: 'Escreva aqui'
	},
	attachmentLimit: {
		id: 'chatMail.attachmentLimit',
		defaultMessage: 'Tamanho não suportado'
	},
	attachmentLimitDescription: {
		id: 'chatMail.attachmentLimitDescription',
		defaultMessage: 'Deve ser menor que'
	},
	notes: {
		id: 'chatInput.notes',
		defaultMessage: 'Criar uma nota'
	},
	tags: {
		id: 'chatInput.tags',
		defaultMessage: 'Aplicar tag'
	}
});

const ChatMail = ({
	inputChat,
	currentInteractionHash,
	onChangeInputText,
	onSendMailMessage,
	onFetchAnswers,
	agent,
	currentInteraction = {},
	onClearAttachmentMail,
	showAnswer,
	onHideAnswer,
	onShowAnswer,
	onShowTag,
	showTags,
	onClearActions,
	onAddAttachment,
	onHideTag,
	onShowAnnotation,
	showAnnotation,
	onHideAnnotation,
	onSendAttachmentMail,
	intl,
	isHistoryInteraction,
	sendAttachmentEmailStatus = { loading: false },
	addNotification
}) => {
	const inputFile = useRef();
	const [answerWithHtml, setAnswerWithHtml] = useState({});
	const [copy, setCopy] = useState('');
	const [subject, setSubject] = useState('');
	const stateValueRef = useRef();
	const [stateValue, setStateValue] = useState('');
	const currentInteractionHashRef = useRef(currentInteractionHash);
	const prevInteractionHash = usePrevious(currentInteractionHash);
	const { formatMessage } = intl;
	const { attachments = [], currentState } = currentInteraction;
	const { loading } = sendAttachmentEmailStatus;
	const blockedStates = ['FINISHING', 'ENDED', 'UNAVAILABLE', 'MISSED'];
	const blockedMessage = blockedStates.includes(currentState) || isHistoryInteraction;
	const permissions = agent && agent.info ? agent.info.permissions : [];
	const canSendAttachment = verifyPermissions(permissions, 'sendAttachment');
	const { shortcutWithHtml } = agent;
	const accountId = agent.info.account.id;
	const departmentId = currentInteraction && currentInteraction.department && currentInteraction.department.id;
	const { value = '' } = inputChat;
	const { blockRemoveTag } = agent;

	useMountEffect(() => {
		setStateValue(value);
		stateValueRef.current = value;

		return () => {
			if (stateValueRef.current !== '') {
				onChangeInputText({
					hash: currentInteractionHashRef.current,
					value: stateValueRef.current
				});
			}
		};
	});

	useEffect(() => {
		if (currentInteractionHash !== prevInteractionHash) {
			onChangeInputText({ hash: prevInteractionHash, value: stateValue });
			setStateValue(value);
			stateValueRef.current = value;
			currentInteractionHashRef.current = currentInteractionHash;
		}
	}, [currentInteractionHash, onChangeInputText, prevInteractionHash, stateValue, value]);

	const onSendMail = useCallback(() => {
		const updatedAttachments = attachments.map(attachment => attachment.url);

		if (stateValue.replace(/\r?\n|\r/g, '').length > 0) {
			let isHtml = false;
			let message = stateValue;

			if (answerWithHtml.body) {
				message = answerWithHtml.body.replace(/\$\{body\}/, stateValue);
				isHtml = true;
			}

			onSendMailMessage({
				cc: copy,
				subject,
				message,
				tempId: new Date().getTime(),
				interactionHash: currentInteractionHash,
				attachments: updatedAttachments,
				isHtml
			}, agent);
			setStateValue('');
			stateValueRef.current = '';
			setSubject('');
			setCopy('');
			setAnswerWithHtml({});
			onClearAttachmentMail({ interactionHash: currentInteractionHash });
		}
	}, [
		agent,
		attachments,
		copy,
		currentInteractionHash,
		onClearAttachmentMail,
		onSendMailMessage,
		stateValue,
		subject,
		answerWithHtml
	]);

	const onFetchDynamicAnswers = useCallback((inputValue, shouldFetch = false) => {
		if (inputValue === '/' || shouldFetch) {
			onFetchAnswers({
				search: '',
				accountId,
				departmentId,
				interactionHash: currentInteractionHash
			});
		} else {
			setTimeout(() => {
				if (stateValueRef.current === inputValue) {
					onFetchAnswers({
						search: inputValue,
						accountId,
						departmentId,
						interactionHash: currentInteractionHash
					});
				}
			}, 1200);
		}
	}, [accountId, departmentId, currentInteractionHash, onFetchAnswers]);


	const toggleAnswer = useCallback((eventValue) => {
		if (showAnswer) {
			return onHideAnswer(currentInteractionHash);
		}
		onFetchDynamicAnswers(eventValue, true);
		onClearActions(currentInteractionHash);
		return onShowAnswer(currentInteractionHash);
	}, [currentInteractionHash, onClearActions, onHideAnswer, onShowAnswer, showAnswer, onFetchDynamicAnswers]);

	const onTypeSubject = e => setSubject(e.target.value);

	const onTypeCopy = e => setCopy(e.target.value);

	const onChange = (e) => {
		const eventValue = e.target.value;
		if (eventValue[0] === '/' && !showAnswer) toggleAnswer(eventValue);
		if (eventValue === '' && showAnswer) toggleAnswer(eventValue);
		if (showAnswer && eventValue !== '') onFetchDynamicAnswers(eventValue);

		setStateValue(eventValue);
		stateValueRef.current = eventValue;
	};

	const onSelectAnswer = useCallback(({
		body, keyword, ishtml, mediaType
	}) => {
		if (mediaType === 'file') {
			const attachment = JSON.parse(body);
			attachment.name = attachment.filename;
			onAddAttachment({ attachment, interactionHash: currentInteractionHash });
			setStateValue('');
		} else if (ishtml === true) {
			setAnswerWithHtml({ body, keyword });
			setStateValue('');
		} else {
			setStateValue(body);
		}

		onHideAnswer(currentInteractionHash);
	}, [currentInteractionHash, onHideAnswer, onAddAttachment]);

	const toggleTags = () => {
		if (showTags) return onHideTag(currentInteractionHash);
		onClearActions(currentInteractionHash);
		return onShowTag(currentInteractionHash);
	};

	const toggleAnnotation = () => {
		if (showAnnotation) return onHideAnnotation(currentInteractionHash);
		onClearActions(currentInteractionHash);
		return onShowAnnotation(currentInteractionHash);
	};

	const onAttachment = () => {
		const { size, type } = inputFile.current.files[0];
		const { approved, attachmentLimit } = letSendAttachment(size, type, 'EMAIL');

		if (approved) {
			const formData = new FormData();

			formData.append('files[]', inputFile.current.files[0]);
			onSendAttachmentMail({
				accountId: agent.info.account.id,
				interactionHash: currentInteraction.interactionHash,
				formData
			});
		} else {
			addNotification({
				title: formatMessage(messages.attachmentLimit),
				content: `${formatMessage(messages.attachmentLimitDescription)} ${attachmentLimit.size}MB`,
				type: 'warning'
			});
		}
	};

	const onClearAttachment = name => onClearAttachmentMail({
		name,
		interactionHash: currentInteractionHash
	});

	return (
		<>
			{showAnnotation && (
				<Annotation onClose={toggleAnnotation} onlyShowValue={isHistoryInteraction} />
			)}
			{showTags && <Tags onClose={toggleTags} onlyShowValue={isHistoryInteraction} blockRemoveTag={blockRemoveTag} />}
			{showAnswer && <Answers onSelectAnswer={onSelectAnswer} onClose={toggleAnswer} currentInteraction={currentInteraction} showShortcutWithHtml={shortcutWithHtml} />}
			<div className="ChatMail">
				<div className="ChatMail__wrapper">
					{!isHistoryInteraction && (
						<>
							<div className="ChatMail__wrapper__input">
								<Text>Cc.:</Text>
								<input placeholder={formatMessage(messages.copyInfo)} onChange={onTypeCopy} value={copy} />
							</div>
							<div className="ChatMail__wrapper__input">
								<input placeholder={formatMessage(messages.subject)} onChange={onTypeSubject} value={subject} />
							</div>
						</>
					)}
					{answerWithHtml.keyword && (
						<TagItem
							tag={{ name: answerWithHtml.keyword }}
							onClick={() => setAnswerWithHtml({})}
							type="remove"
							selected
							action
						/>
					)}
					<div className={`ChatMail__wrapper__main ${blockedMessage ? 'ChatMail__wrapper__main--disabled' : ''}`}>
						<ChatText
							onChange={onChange}
							placeholder={formatMessage(messages.type)}
							disabled={blockedMessage}
							value={stateValue}
						/>
						{attachments && attachments.length > 0 && attachments.map((attachment, index) => (
							<div className="Attachments">
								<AttachmentItem
								// eslint-disable-next-line react/no-array-index-key
									key={index}
									attachment={attachment}
									onClearAttachment={onClearAttachment}
								/>
							</div>
						))}
						<div className="ChatMail__wrapper__actions">
							<div className="ChatMail__wrapper__actions__icons">
								<ChatButton
									ariaLabel={intl.formatMessage(messages.notes)}
									name="notes-off"
									click={toggleAnnotation}
									filled={(currentInteraction && currentInteraction.note !== '') || false}
									type="note"
								/>
								<ChatButton
									ariaLabel={intl.formatMessage(messages.tags)}
									name="tags-off"
									click={toggleTags}
									type="tags"
									filled={currentInteraction && currentInteraction.interactionTags
											&& currentInteraction.interactionTags.length > 0}
								/>
								{!isHistoryInteraction && canSendAttachment && (
									<label
										className="Attachment__label"
										htmlFor="files"
									>
										<input
											type="file"
											id="files"
											className="Attachment__input"
											onChange={onAttachment}
											ref={inputFile}
										/>
										<ImgSvg name="attach-off" />
									</label>
								)}
							</div>
							<div>
								{loading ? <Spinner /> : (
									<Send disabled={!stateValue} onSend={onSendMail} />
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => {
	const { agent, interaction } = state;
	const {
		currentInteractionHash,
		historyInteractions,
		interactions,
		pendingInteractions,
		sendAttachmentEmailStatus
	} = interaction;
	const allInteractions = interactions.concat(pendingInteractions).concat(historyInteractions);

	return ({
		agent,
		currentInteraction: allInteractions.find(({ interactionHash }) => interactionHash === state.interaction.currentInteractionHash) || {},
		currentInteractionHash,
		inputChat: state.interface.inputChat.find(({ hash }) => hash === currentInteractionHash) || { value: '' },
		sendAttachmentEmailStatus: sendAttachmentEmailStatus.find(({ hash }) => hash === currentInteractionHash),
		showAnnotation: state.interface.showingAnnotation.some(hash => hash === currentInteractionHash),
		showTags: state.interface.showingTags.some(hash => hash === state.interaction.currentInteractionHash),
		showAnswer: state.interface.showingAnswer.some(hash => hash === currentInteractionHash)
	});
};

const mapActionsToProps = dispatch => ({
	onChangeInputText: inputChat => dispatch(actions.changeInputText(inputChat)),
	onSendMailMessage: (info, agent) => dispatch(actions.sendMailMessage(info, agent)),
	onShowAnnotation: hash => dispatch(actions.showAnnotation(hash)),
	onHideAnnotation: hash => dispatch(actions.hideAnnotation(hash)),
	onShowAnswer: hash => dispatch(actions.showAnswer(hash)),
	onHideAnswer: hash => dispatch(actions.hideAnswer(hash)),
	onShowTag: hash => dispatch(actions.showTag(hash)),
	onHideTag: hash => dispatch(actions.hideTag(hash)),
	onClearActions: hash => dispatch(actions.clearActions(hash)),
	onSendAttachmentMail: requestInfo => dispatch(actions.sendEmailAttachment(requestInfo)),
	onClearAttachmentMail: nameAndHash => dispatch(actions.clearEmailAttachment(nameAndHash)),
	onAddAttachment: nameAndHash => dispatch(actions.addAttachment(nameAndHash)),
	onFetchAnswers: answerInfo => dispatch(actions.fetchAnswers(answerInfo)),
	addNotification: info => dispatch(actions.addNotification(info))
});

ChatMail.propTypes = {
	inputChat: PropTypes.shape({
		value: PropTypes.string
	}).isRequired,
	onChangeInputText: PropTypes.func.isRequired,
	currentInteractionHash: PropTypes.string.isRequired,
	onSendMailMessage: PropTypes.func.isRequired,
	agent: PropTypes.shape({
		shortcutWithHtml: PropTypes.bool.isRequired,
		info: PropTypes.shape({
			account: PropTypes.shape({
				id: PropTypes.number
			}),
			permissions: PropTypes.arrayOf(PropTypes.string)
		}),
		blockRemoveTag: PropTypes.bool
	}).isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired,
	onShowAnnotation: PropTypes.func.isRequired,
	onHideAnnotation: PropTypes.func.isRequired,
	onAddAttachment: PropTypes.func.isRequired,
	showAnnotation: PropTypes.bool.isRequired,
	onShowAnswer: PropTypes.func.isRequired,
	onHideAnswer: PropTypes.func.isRequired,
	showAnswer: PropTypes.bool.isRequired,
	onShowTag: PropTypes.func.isRequired,
	onHideTag: PropTypes.func.isRequired,
	showTags: PropTypes.bool.isRequired,
	onClearActions: PropTypes.func.isRequired,
	currentInteraction: PropTypes.shape({
		id: PropTypes.number,
		department: PropTypes.shape({
			id: PropTypes.number
		}),
		attachments: PropTypes.arrayOf(PropTypes.shape({
			name: PropTypes.string,
			url: PropTypes.string
		})),
		interactionHash: PropTypes.string,
		currentState: PropTypes.string,
		note: PropTypes.shape({
			body: PropTypes.string,
			url: PropTypes.string,
			s3: PropTypes.shape({
				key: PropTypes.string,
				bucket: PropTypes.string,
				filename: PropTypes.string
			})
		}),
		interactionTags: PropTypes.arrayOf(PropTypes.shape({}))
	}),
	isHistoryInteraction: PropTypes.bool,
	onSendAttachmentMail: PropTypes.func.isRequired,
	onClearAttachmentMail: PropTypes.func.isRequired,
	sendAttachmentEmailStatus: PropTypes.shape({
		loading: PropTypes.bool
	}),
	onFetchAnswers: PropTypes.func.isRequired,
	addNotification: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapActionsToProps)(injectIntl(ChatMail));
